import React from "react";
import {Navigate} from "react-router-dom";
import { useAppContext } from "./services/ContextService";

export default function UnauthenticatedRoute({ children, ...rest}) {
    const { isAuthenticated } = useAppContext();

    if (isAuthenticated) {
        return <Navigate to="/"/>
    }
    return children
}