import React from 'react';
import { AppContext } from "../services/ContextService";
import ContactService from "../services/ContactService";
import "../styles/Display.css";

export default class InviteContact extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            emailsToAdd: [],
            email: '',
            message: ''
        };
    }

    addContactToList = () => {
        if (!this.state.email) {
            this.setState({message: "No email found. Please enter an email"});
        } else if (!/[^@\s]+@[^@\s]+\.[^@\s]+/.test(this.state.email)) {
            this.setState({message: "Please enter a valid email"});
        } else if (!this.state.emailsToAdd.includes(this.state.email)) {
            this.setState({emailsToAdd: this.state.emailsToAdd.concat(this.state.email), message: "", email: ""});
        }
    }

    sendInvitations = async () => {
        if (this.state.emailsToAdd.length > 0) {
            try {
                let response = await ContactService.sendInvitations(this.state.emailsToAdd);
                if (response.failed.length === 0) {
                    this.setState({message: "Invitations have been sent", emailsToAdd: []});
                } else {
                    this.setState({message: `Invitations could not have been sent to these emails : ${response.failed}`});
                }
            } catch (e) {
                this.setState({message: "An error occurred while adding these emails : ", e})
            }
        }
    }

    onEmailChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        this.setState({[name]: val, message: ""});
    }

    deleteEmail = (index) => {
        let emailsList = this.state.emailsToAdd;
        emailsList.splice(index, 1);
        this.setState({emailsToAdd : emailsList});
    }

    render() {
        let rows = this.state.emailsToAdd.map((user, index) => (
            <tr key={index}>
                <td>{user}</td>
                <td><button className="redbutton" onClick={() => this.deleteEmail(index)}>Delete</button></td>
            </tr>
        ))
        return <div className="full-width">
            <div>
                <label htmlFor="emailInput">Add to network:</label> <br />
                <input id="emailInput" type="text" name='email' onChange={this.onEmailChangeHandler} placeholder="Email" value={this.state.email}/>

                <button type="button" onClick={this.addContactToList}>+</button> <br/>
                <p>{this.state.message}</p>
                <div>
                    {
                        (this.state.emailsToAdd.length > 0) &&
                        <>
                            <h2>List of contacts to add :</h2>
                            <table>
                                <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Remove user from list</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                        </>
                    }
                </div>
                <button onClick={this.sendInvitations}>Send invitation(s)</button>
            </div>
        </div>
    }
}
