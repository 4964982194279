import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "./services/ContextService";


export default function AuthenticatedRouteSupervisor({ children, ...rest}) {
    const { isAuthenticated, rights, selectedChat } = useAppContext();
    const isSupervisor = rights[selectedChat] === "admin";

    if (!isAuthenticated && !isSupervisor) {
        return <Navigate to="/"/>
    }

    return children
}
