import countryCodes from "../config/countryCodes.json";
import accountRelationship from "../config/accountRelationship.json";
import ConfigService from "./ConfigService";
import languages from "../config/languages.json";
import LeadService from "../services/LeadService";

export async function getLeadDetails(customDataLead, sourceName, chat) {
    if (customDataLead["sfdcId"]) {
        let sfdcExistingLead = await LeadService.getLead(customDataLead["sfdcId"]);
        return {
            "LastName": sfdcExistingLead["LastName"],
            "FirstName": sfdcExistingLead["FirstName"],
            "Email": sfdcExistingLead["Email"],
            "Phone": sfdcExistingLead["Phone"],
            "Company": sfdcExistingLead["Company"],
            "Country__c": sfdcExistingLead["Country__c"],
            "Relationship__c": sfdcExistingLead["Relationship__c"],
            "Chat_Purpose__c": sfdcExistingLead["Chat_Purpose__c"],
            "Industry": sfdcExistingLead["Industry"],
            "Market_Category__c": sfdcExistingLead["Market_Category__c"],
            "Language_lead__c": sfdcExistingLead["Language_lead__c"],
            "LeadSource": sfdcExistingLead["LeadSource"],
            "Status": sfdcExistingLead["Status"],
            "Campaign_Source_Name__c": sfdcExistingLead["Campaign_Source_Name__c"],
            "SourceName__c": sfdcExistingLead["SourceName__c"],
            "CurrencyIsoCode": sfdcExistingLead["CurrencyIsoCode"],
            "recordTypeId": sfdcExistingLead["recordTypeId"],
            "Description": sfdcExistingLead["Description"],
            "Appointment_Comments__c": sfdcExistingLead["Appointment_Comments__c"],
        }
    } else {
        return {
            "LastName": customDataLead["lastName"] || "",
            "FirstName": customDataLead["firstName"] || "",
            "Email": customDataLead["email"] || "",
            "Phone": "",
            "Company": customDataLead["companyName"] || "",
            "Country__c": getSfdcId(countryCodes, customDataLead["country"]),
            "Relationship__c": getRelationship(accountRelationship, customDataLead["customerType"]),
            "Chat_Purpose__c": getSfdcId(ConfigService.reasonForChat(chat), customDataLead["reasonForChat"]),
            "Industry": "",
            "Market_Category__c": "",
            "Language_lead__c": getSfdcId(languages, customDataLead["language"]),
            "LeadSource": "Chat",
            "Status": "New",
            "Campaign_Source_Name__c": "WW_ENT_CHAT_EN_Worldwide-Chat",
            "SourceName__c": sourceName,
            "CurrencyIsoCode": "",
            "recordTypeId": "01220000000cRyYAAU",
            "Description": "",
            "Appointment_Comments__c": ""
        }
    }
}

const getSfdcId = ((valuesList, valueToSearch) => {
    let filteredList = valuesList.filter((valuesList) => valuesList["value"] === valueToSearch)
    if (filteredList.length > 0) {
        return filteredList[0]["sfdcId"]
    }
    return "";
})

const getRelationship = ((valuesList, valueToSearch) => {
    if (!valueToSearch) {
        return "";
    }
    if (valueToSearch.length === 0) {
        valueToSearch = "NeitherCustomerOrBusinessPartner"
    }
    return getSfdcId(valuesList, valueToSearch)
})
