import React from "react";
import Select from "react-select";
import {useAppContext} from "../services/ContextService";
import "../styles/Display.css";
import ConfigService from "../services/ConfigService";

function ReasonSelect() {
    const context = useAppContext()

    function reasonForChatChange(selectedOption) {
        context.setUserReasons(selectedOption)
    }

    return <Select placeholder={"Reason for chat"} isMulti options={ConfigService.reasonsForChatSelect(context.selectedChat)} value={context.selectedReasons} onChange={reasonForChatChange}/>

}

export default ReasonSelect;
