import React from 'react';
import { AppContext } from "../services/ContextService";
import ContactService from "../services/ContactService";
import "../styles/Display.css";

export default class GetContact extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            contacts: [],
            message: "",
        };
    }

    componentDidMount() {
        this.getAllContacts();
    }

    getAllContacts = async () => {
        try {
            let response = await ContactService.getAllContacts();
            this.setState({contacts: response.contacts});
        } catch (e) {
            this.setState({message: "An error occurred while retrieving all contacts", e});
        }
    }

    removeContact = async (email, index) => {
        try {
            let response = await ContactService.removeContact(email);
            if (response.failed === "") {
                let contactList = this.state.contacts;
                contactList.splice(index, 1);
                this.setState({message: "Successfully removed "+ email + " from contact list", contacts: contactList});
            } else {
                this.setState({message: `Error while removing ${email} : ${response.failed}`});
            }
        } catch (e) {
            this.setState({message: "An error occurred while retrieving all contacts", e});
        }
    }

    render() {
        let rows = this.state.contacts.map((user, index) => (
            <tr key={index}>
                <td>{user.loginEmail}</td>
                <td>{user.name}</td>
                <td><button className="redbutton" onClick={() => this.removeContact(user.loginEmail, index)}>Delete</button></td>
            </tr>
        ))
        return <div className="full-width">
            <div>
                {this.state.message}
                <div>
                    {
                        (this.state.contacts.length > 0) &&
                        <>
                            <label>Contact list:</label>
                            <button type="button" onClick={this.getAllContacts}>Refresh</button>
                            <table>
                                <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Name</th>
                                    <th>Remove contact</th>
                                </tr>
                                </thead>
                                <tbody>
                                {rows}
                                </tbody>
                            </table>
                        </>
                    }
                </div>
            </div>
        </div>
    }
}
