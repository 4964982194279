import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "./services/ContextService";

export default function AuthenticatedRoute({ children }) {
    const { isAuthenticated } = useAppContext();

    if (!isAuthenticated) {
        return <Navigate to="/"/>
    }

    return children
}
