import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "./services/ContextService";


export default function AuthenticatedRouteSupervisor({ children, ...rest}) {
    const { isAuthenticated, rights, selectedChat } = useAppContext();
    const isAuthorized = ["operator", "admin"].includes(rights[selectedChat]);

    if (!isAuthenticated && !isAuthorized) {
        return <Navigate to="/"/>
    }

    return children
}
