import axios from 'axios'
import ConfigService from "../services/ConfigService";

class GroupService {
    getAllGroups() {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/group"
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    addContactInGroup(groupName, email) {
        return new Promise((resolve, reject) => {
            axios.post(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/group",
                {
                    groupName: groupName,
                    contactEmail: email
                }
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    removeContactFromGroup(groupName, email) {
        return new Promise((resolve, reject) => {
            axios.delete(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/group/"+groupName+"/"+email
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }
}

const groupService = new GroupService();
export default groupService;
