import React from "react";
import { Route, Routes as Switch} from "react-router-dom";
import Home from "./components/Home";
import RunReportsForm from "./components/ReportManagement";
import BubbleDetails from "./components/BubbleDetails";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import Contacts from "./components/Contacts";
import Groups from "./components/Groups";
import BubblesActive from "./components/BubblesActive";
import Leads from "./components/Leads";
import BubblesClosed from "./components/BubblesClosed";
import AuthenticatedRoute from "./AuthenticatedRoute.js";
import AuthenticatedRouteSupervisor from "./AuthenticatedRouteSupervisor.js";
import AuthenticatedRouteOperator from "./AuthenticatedRouteOperator.js";
import UnauthenticatedRoute from "./UnauthenticatedRoute.js";

export default function Routes() {
    return (
        <Switch>
            <Route path="/" element={<Home />}/>
            <Route path="/:chat/contact" element={
                <AuthenticatedRouteSupervisor>
                    <Contacts />
                </AuthenticatedRouteSupervisor>
            }/>
            <Route path="/:chat/groups" element={
                <AuthenticatedRouteSupervisor>
                    <Groups />
                </AuthenticatedRouteSupervisor>
            }/>
            <Route path="/:chat/lead/:bubbleId" element={
                <AuthenticatedRoute>
                    <Leads/>
                </AuthenticatedRoute>
            }/>
            <Route path="/:chat/active-bubbles" element={
                <AuthenticatedRouteOperator>
                    <BubblesActive />
                </AuthenticatedRouteOperator>
            }/>
            <Route path="/:chat/closed-bubbles" element={
                <AuthenticatedRouteOperator>
                    <BubblesClosed />
                </AuthenticatedRouteOperator>
            }/>
            <Route path="/login" element={
                <UnauthenticatedRoute>
                    <Login />
                </UnauthenticatedRoute>
            }/>
            <Route path="/:chat/report-management" element={
                <AuthenticatedRouteSupervisor>
                    <RunReportsForm />
                </AuthenticatedRouteSupervisor>
            }/>
            <Route path="/:chat/bubble/:bubbleId" element={
                <AuthenticatedRoute>
                    <BubbleDetails />
                </AuthenticatedRoute>
            }/>
            <Route element={<NotFound />}/>
        </Switch>
    );
}
