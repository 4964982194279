import React from 'react';
import { AppContext } from "../services/ContextService";
import BubbleService from "../services/BubbleService";
import ReasonSelect from "./ReasonSelector";
import "../styles/Display.css";

export default class BubblesActive extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            bubbles: [],
            showPopup: false,
            conversation: [],
            message: ''
        };
    }

    componentDidMount() {
        this.getAllActiveBubbles();
    }

    compareCreationDate = (a, b) => {
        if (a.creationDate < b.creationDate) return 1;
        else return -1;
    }


    getAllActiveBubbles = async () => {
        try {
            let response = await BubbleService.getAllActiveBubbles();
            const sortedBubblesList = response.bubbles.sort(this.compareCreationDate);
            this.setState({bubbles: sortedBubblesList});
        } catch (e) {
            this.setState({message: "Error occurred while getting all active bubbles : " + e});
        }
    }

    archiveBubble = async (bubbleName, bubbleId, index) => {
        try {
            await BubbleService.archiveBubble(bubbleId);
            let bubbles = this.state.bubbles;
            bubbles.splice(index, 1);
            this.setState({bubbles: bubbles, message: "Successfully archived bubble : " + bubbleName});
        } catch (e) {
            this.setState({message: "Error occurred while archiving bubble : " + bubbleName + ". " + e});
        }
    }

    render() {
        const reasonsList = this.context.selectedReasons.map((reason) => reason.value)
        let bubbles = this.state.bubbles.map((bubble, index) => (
            (!bubble.customData.lead || reasonsList.includes(bubble.customData.lead.reasonForChat)) && <tr key={index}>
                <td className={"no-border"}><a href={"/" + this.context.selectedChat + "/bubble/" + bubble.id}>{bubble.name}</a></td>
                <td className={"no-border"}>{bubble.customData.agentLanguage}</td>
                <td className={"no-border"}>{bubble.customData.agents &&
                                             bubble.customData.agents.length > 0 &&
                                             bubble.customData.agents[0] &&
                                             bubble.customData.agents[0].split('@')[0].replace('.', ' ')}</td>
                <td className={"no-border"}>{bubble.creationDate.slice(0, 19).replace('T', ' ')}</td>
                <td className={"no-border"}>
                    {
                        bubble.lastActivityDate ? bubble.lastActivityDate.slice(0, 19).replace('T', ' ')
                        : ""
                    }
                </td>
                <td className={"no-border"}>
                    {
                        bubble.customData.lead === undefined ? <span>Guest filling the form</span>
                        : <a href={"/" + this.context.selectedChat + "/lead/" + bubble.id}>Lead{bubble.customData.lead.sfdcId && " created"}</a>
                    }
                </td>
                <td className={"td-right no-border"}><button className="redbutton" onClick={() => this.archiveBubble(bubble.name, bubble.id, index)}>Archive</button></td>
            </tr>
        ))
        return <div>
            <div className="full-width"><ReasonSelect /></div>
            <table className={"no-border"}>
                <thead>
                    <tr>
                        <th className={"no-border"}>Bubble name</th>
                        <th className={"no-border"}>Language</th>
                        <th className={"no-border"}>Agent Name</th>
                        <th className={"no-border"}>Creation date</th>
                        <th className={"no-border"}>Last activity date</th>
                        <th className={"no-border"}>Lead</th>
                        <th className={"td-right no-border"}>
                            <button type="button" onClick={this.getAllActiveBubbles}>Refresh</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {bubbles}
                </tbody>
            </table>
        </div>
    }
}
