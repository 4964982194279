import { Auth } from "aws-amplify";
import { onError } from "../services/ErrorService";
import "../styles/Login.css";

export default function Login() {
    async function login() {
        try
        {
            await Auth.federatedSignIn({customProvider: 'Alcatel-Lucent-Enterprise'});
        } catch(e) {
            onError(e);
        }
    }

    login();
}
