import React from 'react';
import LambdaService from '../services/LambdaService';
import {AppContext} from "../services/ContextService";
import "../styles/Display.css";
import ConfigService from "../services/ConfigService";
import ReasonSelect from "./ReasonSelector";

export default class RunReportsForm extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        let today = new Date();
        let yesterday = new Date()
        yesterday.setDate(today.getDate() - 1);
        let yesterday_string = yesterday.toISOString().substr(0,10);

        this.state = {
            message: '',
            errorMessage: '',
            reportDate: yesterday_string,
            granularity: "day",
            summaryMessage: '',
            bubblesSummary: [],
            detailedBubbleName: '',
            bubbleDetails: [],
            dashboardButtonMessage: '',
            detailsButtonMessage: '',
            summaryButtonMessage: '',
            inactiveButtons: false,
        };

        this.statusExplanation = "Missed: No agents available\n" +
            "Silent Guest: Guest joined but did not speak\n" +
            "Refused by guest: No agent available in user language and guest refused english chat\n" +
            "Real Chat: Real chat session";

        this.refEventsForBubbleSection = React.createRef();
    }

    componentDidMount() {
        this.getSummary(this.state.reportDate, this.state.granularity);
    }

    reloadForm = () => {
        this.getSummary(this.state.reportDate, this.state.granularity);
    }

    myDateChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        this.setState({[name]: val, message: "", bubblesSummary: [], bubbleDetails: []});
        this.getSummary(val, this.state.granularity);
    }

    myBoolChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.checked;
        this.setState({[name]: val, message: ""});
    }

    setGranularity = (event) => {
        const granularityValue = event.target.value
        this.setState({granularity: granularityValue});
        this.getSummary(this.state.reportDate, granularityValue);
    }

    getSummary = (report_date, granularity) => {
        if (report_date !== '') {
            this.setState({errorMessage: "", summaryMessage: "Query bubble data ...", bubblesSummary: [], bubbleDetails: []});
            const param = {event_date: report_date, granularity: granularity, chat: this.context.selectedChat};
            LambdaService.getEventsSummary(param).then(response => {
                if (response.status === 200) {
                    this.setState({summaryMessage: "", bubblesSummary: response.data});
                } else
                    this.setState({summaryMessage: "No data returned", bubblesSummary: []});
            }).catch(err => {
                this.setState({summaryMessage: "Error while querying bubbles"});
                console.log(err);
            })
        } else {
            this.setState({summaryMessage: "You need to enter the date to get the bubble summary"});
        }
    }

    getBubbledetails = (event) => {
        let bubble_id = event.target.id;
        let bubbleName = event.target.textContent;
        this.setState({
            detailedBubbleName: bubbleName,
            errorMessage: "",
            summaryMessage: "Query bubble details ..."
        });
        let bubble_events = this.state.bubblesSummary.filter((bubble) => bubble.bubble_id === bubble_id);
        if (bubble_events.length > 0) {
            this.setState({summaryMessage: "", bubbleDetails: bubble_events});
        } else {
            this.setState({summaryMessage: "No data returned", bubblesDetails: []});
        }
        this.refEventsForBubbleSection.current.scrollIntoView();
    }

    saveToFile(url) {
        let elementA = document.createElement("a");
        document.body.appendChild(elementA);
        elementA.style.display = "none";
        elementA.href = url;
        elementA.click();
    }

    runReports = (event) => {
        let report_name = event.target.name;
        if (this.state.reportDate !== '') {
            if (this.state.inactiveButtons) {
                this.setState({[report_name + "ButtonMessage"]: "Please wait ..."});
            } else {
                this.setState({
                    errorMessage: "", [report_name + "ButtonMessage"]: "Running report ...",
                    inactiveButtons: true
                });
                LambdaService.runReport(this.state.reportDate, report_name, this.state.granularity, this.context.selectedReasons, this.context.selectedChat).then(response => {
                    if (response.status === 200) {
                        this.saveToFile(response.data.file_url)
                        this.setState(this.clearWaitMessages({}));
                    } else
                        this.setState(this.clearWaitMessages({
                            [report_name + "ButtonMessage"]: "No data returned"}));
                }).catch(err => {
                    this.setState(this.clearWaitMessages({
                        [report_name + "ButtonMessage"]: "Error " + err.status}));
                    console.log(err);
                })
            }
        } else {
            this.setState({errorMessage: "You need to enter the date of the report"});
        }
    }

    clearWaitMessages = (state) => {
        let newState = {dashboardButtonMessage: "", detailsButtonMessage: "", summaryButtonMessage: "",
            inactiveButtons: false}
        for (const [key, value] of Object.entries(state)) {
            newState[key] = value
        }
        console.log('new state', newState)
        return newState
    }

    reasonForChatlabel = (reasonId) => {
        return ConfigService.reasonForChat(this.context.selectedChat).filter((valuesList) => valuesList["value"] === reasonId)[0].label
    }

    compareCreationDate = (a, b) => {
        if (a.first_event_datetime < b.first_event_datetime) return 1;
        else return -1;
    }

    buildBubblesList = (bubblesSummary) => {
        try {
            const reasonsList = this.context.selectedReasons.map((reason) => reason.value)
            return bubblesSummary.sort(this.compareCreationDate).map((bubble, index) => {
                    if (bubble.language && reasonsList.includes(bubble.reason_for_chat)) {
                        return <tr key={index}>
                            <td className={"no-border; active-cell"} onClick={this.getBubbledetails} id={bubble.bubble_id}
                                data-value={bubble.bubble_name}>
                                {bubble.bubble_name ? bubble.bubble_name : "Unnamed " + (index + 1)}
                            </td>
                            <td className={"no-border"}>{bubble.status}</td>
                            <td className={"no-border"}>{this.reasonForChatlabel(bubble.reason_for_chat)}</td>
                            <td className={"no-border centered-cell"}>{bubble.language}</td>
                            <td className={"no-border"}>{bubble.first_event_datetime.substr(0, 16).replace('T', ' ')}</td>
                            <td className={"no-border"}>{bubble.end_time && bubble.end_time.substr(11, 5)}</td>
                            <td className={"no-border centered-cell"}>{this.fmtMSS(bubble.duration_seconds)}</td>
                            {/*<td className={"no-border"}>{bubble.agent_names}</td>*/}
                            <td className={"no-border centered-cell"}>{bubble.guest_message_number}</td>
                            <td className={"no-border centered-cell"}>{bubble.agent_message_number}</td>
                            <td className={"no-border centered-cell"}>{bubble.guest_message_number && bubble.first_agent_response_timestamp &&
                            this.fmtMSS(Math.round(bubble.first_agent_response_timestamp - bubble.first_guest_message_timestamp))}</td>
                        </tr>
                    } else {
                        return []
                    }
                }
            )
        } catch (err) {
            console.log("Exception occurred while creating bubbles list: ", err)
            return []
        }
    }

    buildEventsList = (bubbleSummary) => {
        if (bubbleSummary.length > 0) {
            console.log(bubbleSummary[0].events)
            return bubbleSummary[0].events.map((event, index) => {
                    return <tr key={index}>
                        <td className={"no-border"}>{event.action}</td>
                        <td className={"no-border"}>{event.timestamp.substr(11, 8)}</td>
                        <td className={"no-border"}>{event.sfdc_lead_id ?
                            <a href={ConfigService.config.sfdcInstance.SFDC_URL + "/" + event.sfdc_lead_id} target="_blank"
                               rel="noreferrer">SalesForce Lead</a> :
                            event.name}</td>
                        <td className={"no-border"}>{event.text} {event.user_language} {event.user_email}</td>
                    </tr>
                }
            )
        } else return []
    }

    granularityReport = () => {
        if (this.state.granularity === 'day') return 'daily ';
        else if (this.state.granularity === 'week') return 'weekly ';
        else if (this.state.granularity === 'month') return 'monthly ';
    }

    granularitySummaryReport = () => {
        if (this.state.granularity === 'day') return 'weekly ';
        else if (this.state.granularity === 'week') return 'weekly ';
        else if (this.state.granularity === 'month') return 'monthly ';
    }

    fmtMSS = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s}

    render() {
        let bubbles = this.buildBubblesList(this.state.bubblesSummary)

        let events = this.buildEventsList(this.state.bubbleDetails)

        return <div className="full-width">
            <div>
                <div className={"right"}>
                    <label>Filter on reason for chat: </label>
                    <ReasonSelect />
                </div>
                <div className={"left"}>
                    <div className={"left"}>
                        <li className="nochip">
                            <label>Report date: </label><input type='date' name='reportDate' defaultValue={this.state.reportDate}
                                                onChange={this.myDateChangeHandler}/>
                        </li>
                    </div>
                    <div className={"right"}><label>Granularity: </label>
                        <div>
                            <li className="nochip"><input type="radio" value="day" name="granularity"
                                                          checked={this.state.granularity === "day"}
                                                          onChange={this.setGranularity}/> Day
                            </li>
                            <li className="nochip"><input type="radio" value="week" name="granularity"
                                                          checked={this.state.granularity === "week"}
                                                          onChange={this.setGranularity}/> Week
                            </li>
                            <li className="nochip"><input type="radio" value="month" name="granularity"
                                                          checked={this.state.granularity === "month"}
                                                          onChange={this.setGranularity}/> Month
                            </li>
                        </div>
                    </div>
                </div>
            </div>
            <div className="full-width">

                <h1>Run a report and download</h1>
                <p>{this.state.errorMessage}</p>
                <table className={"buttons-table"}>
                    <tbody>
                    <tr className={"buttons-table"}>
                        <td width={"33%"} className={"buttons-table"}>
                            <button className={this.state.inactiveButtons ? "inactivebutton" : ""}
                                    name={"dashboard"} onClick={this.runReports}>
                                Download {this.granularityReport()}Dashboard<br/>PDF</button>
                        </td>
                        <td width={"33%"} className={"buttons-table"}>
                            <button className={this.state.inactiveButtons ? "inactivebutton" : ""}
                                    name={"details"} onClick={this.runReports}>
                                Download {this.granularityReport()}Detailed Report<br/>XLSX</button>
                        </td>
                        <td width={"33%"} className={"buttons-table"}>
                            <button className={this.state.inactiveButtons ? "inactivebutton" : ""}
                                    name={"summary"} onClick={this.runReports}>
                                Download {this.granularitySummaryReport()}Summary Report<br/>XLSX</button>
                        </td>
                    </tr>
                    <tr className={"buttons-table"}>
                        <td className={"buttons-table"}>
                            {this.state.dashboardButtonMessage}
                        </td>
                        <td className={"buttons-table"}>
                            {this.state.detailsButtonMessage}
                        </td>
                        <td className={"buttons-table"}>
                            {this.state.summaryButtonMessage}
                        </td>
                    </tr>
                    </tbody>
                </table>
                <p>{this.state.message}</p>
                <div className={"full-width"}>
                    <h1>Bubbles summary  <button name={"reload"} onClick={this.reloadForm}>Reload</button></h1>
                    <p>{this.state.summaryMessage}</p>
                </div>
                {(bubbles.length > 0) && <table className={"no-border"}>
                    <thead>
                    <tr>
                        <th className={"no-border"}>Bubble name</th>
                        <th title={this.statusExplanation} className={"no-border"}>Status*</th>
                        <th className={"no-border"}>Reason for chat</th>
                        <th className={"no-border"}>Language</th>
                        <th className={"no-border"}>Start date time</th>
                        <th className={"no-border"}>End</th>
                        <th className={"no-border centered-cell"}>Duration (m:s)</th>
                        {/*<th className={"no-border centered-cell"}>Agent names</th>*/}
                        <th className={"no-border centered-cell"}>Guest messages</th>
                        <th className={"no-border centered-cell"}>Agent messages</th>
                        <th className={"no-border centered-cell"}>Response time (m:s)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bubbles}
                    </tbody>
                </table>}

                <div ref={this.refEventsForBubbleSection}/>
                {(events.length > 0) && <div>
                <h1>Events for bubble {this.state.detailedBubbleName}</h1>
                <table className={"no-border"}>
                    <thead>
                    <tr>
                        <th className={"no-border"}>Action</th>
                        <th className={"no-border"}>Time</th>
                        <th className={"no-border"}>Name</th>
                        <th className={"no-border"}>Text</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events}
                    </tbody>
                </table>
                </div>}
            </div>
        </div>
    }
}
