import React from 'react';
import { AppContext } from "../services/ContextService";
import "../styles/Display.css";
import InviteContact from "./InviteContact";
import GetContact from "./GetContact";

export default class Contacts extends React.Component {
    static contextType = AppContext;

    componentDidMount() {
    }

    render() {

        return <div>
            <InviteContact />
            <GetContact />
        </div>
    }
}
