import axios from 'axios'
import ConfigService from "../services/ConfigService";

class LeadService {

    createLead(data) {
        return new Promise((resolve, reject) => {
            axios.post(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/lead",
                data
            ).then(response => {
                console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.response ? err.response.status : err)
                reject(err.response ? err.response : {status: 500});
            })
        })
    }

    getLead(sfdcLeadId) {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/lead/" + sfdcLeadId
            ).then(response => {
                console.log(response);
                resolve(response.data.lead);
            }).catch(err => {
                console.log("Response error code", err.response ? err.response.status : err)
                reject(err.response ? err.response : {status: 500});
            })
        })
    }
}

const leadService = new LeadService();
export default leadService;
