import axios from 'axios'
import ConfigService from "../services/ConfigService";

class ConversationService {
    getConversation(bubbleJid) {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/conversation/" + bubbleJid
            ).then( response => {
                console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err)
                reject(err);
            })
        })
    }
}

const conversationService = new ConversationService();
export default conversationService;
