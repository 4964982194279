import React from "react";
import { withCookies } from "react-cookie";
import { Nav, Navbar, NavDropdown} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import { Auth } from "aws-amplify";
import { AppContext } from "../services/ContextService";
import axios from "axios";
import { onError } from "../services/ErrorService";
import Routes from "../Routes";
import "../styles/App.css";
import config from '../config/config.json'
import ConfigService from "../services/ConfigService";
import {NavLink} from "react-router-dom";

class App extends React.Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.cookies = cookies;
        this.state = {
            isAuthenticating : true,
            rights : this.cookies.get("chat_rights") || {},
            isAuthenticated :false,
            setChat: this.setChat,
            userHasAuthenticated : this.userHasAuthenticated,
            selectedReasons : this.cookies.get("reasons") || [],
            setUserReasons : this.setUserReasons,
            chatsAccess : this.cookies.get("chat_access") || [],
            selectedChat : this.cookies.get("selected_chat") || "Choose your chat",
        }

        if (this.cookies.get("selected_chat")) {
            ConfigService.setUrl(config.chatServer[this.cookies.get("selected_chat")].url);
        }

        this.activeNavLink = {
            color: "white"
        }

        this.unactiveNavLink = {
            color: "rgba(255,255,255,.5)"
        }

        this.aleChat = "ALE";
        this.bpChat = "BP";
    }

    setIsAuthenticating = (boolean) => this.setState({isAuthenticating : boolean})
    setRights = (rights) => {
        this.cookies.set("chat_rights", rights, {path: "/", maxAge: 86400});
        this.setState({rights: rights})
    }
    userHasAuthenticated = (boolean) => this.setState({isAuthenticated : boolean})
    setUserReasons = (reasons) => {
        this.cookies.set("reasons", reasons, {path: "/", maxAge: 86400});
        this.setState({selectedReasons: reasons})
    }
    setChatsAccess = (access) => {
        this.cookies.set("chat_access", access, {path: "/", maxAge: 86400});
        this.setState({chatsAccess: access})
    }
    setChat = (chat) => {
        this.cookies.set("selected_chat", chat, {path: "/", maxAge: 86400});
        console.log("[App] Set chat selectedChat to : " + chat)
        this.setState({selectedChat: chat})
    }

    handleLogout = () => {
        Auth.signOut().then( () => {
            this.userHasAuthenticated(false);
            this.props.history.push("/");
        })
    }

    handleChatSelection = (event) => {
        ConfigService.setUrl(config.chatServer[event.target.value].url);
        this.setChat(event.target.value);
        this.setUserReasons(ConfigService.reasonsForChatSelect(event.target.value));
        window.location.reload();
    }

    getChatsAccess = (payload) => {
        let chats = [];
        if (payload["access_ale_chat"])
            chats.push(this.aleChat);

        if (payload["access_bp_chat"])
            chats.push(this.bpChat);

        return chats;
    }

    getChatsRights = (chats, rights) => {
        let localRight = {}
        for (let chat of chats){
            if(rights.includes(config.chatServer[chat].rights.admin)){
                localRight[chat] = "admin";
            } else if (rights.includes(config.chatServer[chat].rights.operator)) {
                localRight[chat] = "operator";
            }
        }
        return localRight;
    }

    async componentDidMount() {
        try {
            await this.setAuthorizationHeader();
            let payload = (await Auth.currentSession()).getIdToken().decodePayload();
            this.setChatsAccess(this.getChatsAccess(payload));
            this.setRights(this.getChatsRights(this.state.chatsAccess, payload["cognito:groups"]));
            console.log(this.state.rights);
            this.userHasAuthenticated(true);

            // Set default selected chat based on url
            const pathSplited = window.location.pathname.split("/");
            console.log("Path: " + pathSplited)
            if (pathSplited.length >= 2) {
                // At least a path of this form "/"
                const chat = pathSplited[1];
                if ([this.aleChat, this.bpChat].includes(chat)) {
                    this.setChat(chat);
                }
            }
        }
        catch(e) {
            if (e !=='No current user') {
                onError(e);
            }
            this.cookies.remove("chat_access");
            this.cookies.remove("selected_chat");
            this.cookies.remove("chat_rights");
            this.cookies.remove("reasons");
        }
        this.setIsAuthenticating(false);
    }

    setAuthorizationHeader = async () => {
        axios.interceptors.request.use(config => new Promise((resolve) => {
            Auth.currentSession()
                .then((session) => {
                    const idToken = session.getIdToken().getJwtToken();
                    if (config.headers.Authorization !== idToken) {
                        config.headers['Content-Type'] = 'application/json';
                        config.headers.Authorization = idToken;
                    }
                    resolve(config);
                })
                .catch(() => {
                    // Can't get session, logout user
                    resolve(config);
                });
        }));
    }

    render() {
        return (
            !this.state.isAuthenticating &&
            <div className="App container">
                <Navbar collapseOnSelect expand="lg" variant="dark">
                    <NavLink to="/">
                        <Navbar.Brand>Universal Live Chat Management</Navbar.Brand>
                    </NavLink>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <NavbarCollapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            {this.state.isAuthenticated &&
                            <div>
                                {this.state.rights[this.state.selectedChat] === "admin" && <Navbar.Text>
                                    <NavLink to={"/" + this.state.selectedChat + "/contact"}
                                             style={({ isActive }) =>
                                                 isActive ? this.activeNavLink : this.unactiveNavLink
                                             }>
                                        Contacts
                                    </NavLink>
                                </Navbar.Text>}
                                {this.state.rights[this.state.selectedChat] === "admin" && <Navbar.Text>
                                    <NavLink to={"/" + this.state.selectedChat + "/groups"}
                                             style={({ isActive }) =>
                                                 isActive ? this.activeNavLink : this.unactiveNavLink
                                             }>
                                        Groups
                                    </NavLink>
                                </Navbar.Text>}
                                {["operator", "admin"].includes(this.state.rights[this.state.selectedChat]) && <Navbar.Text>
                                    <NavLink to={"/" + this.state.selectedChat + "/active-bubbles"}
                                             style={({ isActive }) =>
                                                 isActive ? this.activeNavLink : this.unactiveNavLink
                                             }>
                                        Active Bubbles
                                    </NavLink>
                                </Navbar.Text>}
                                {["operator", "admin"].includes(this.state.rights[this.state.selectedChat]) && <Navbar.Text>
                                    <NavLink to={"/" + this.state.selectedChat + "/closed-bubbles"}
                                             style={({ isActive }) =>
                                                 isActive ? this.activeNavLink : this.unactiveNavLink
                                             }>
                                        Closed Bubbles
                                    </NavLink>
                                </Navbar.Text>}
                                {this.state.rights[this.state.selectedChat] === "admin" && <Navbar.Text>
                                    <NavLink to={"/" + this.state.selectedChat + "/report-management"}
                                             style={({ isActive }) =>
                                                 isActive ? this.activeNavLink : this.unactiveNavLink
                                             }>
                                        Reports
                                    </NavLink>
                                </Navbar.Text>}
                            </div>
                            }
                        </Nav>
                        <Nav className="flex-md-row-reverse">
                            {this.state.isAuthenticated ?
                                <>
                                    <NavLink onClick={this.handleLogout} className="logout" to="/" >Logout</NavLink>
                                    <NavDropdown id="collapsible-nav-dropdown" title={this.state.selectedChat} defaultValue={this.state.selectedChat}>
                                        {
                                            this.state.chatsAccess.map((chat, key) => (
                                                <NavDropdown.Item as="button" key={key} onClick={this.handleChatSelection}
                                                                  value={chat}>{chat}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                </>
                                : <NavLink to="/login" className="login">
                                    Login
                                </NavLink>
                            }
                        </Nav>
                    </NavbarCollapse>
                </Navbar>
                <AppContext.Provider value={this.state}>
                    <Routes/>
                </AppContext.Provider>
                <section className="image"/>
            </div>
        );
    }
}

export default withCookies(App);
