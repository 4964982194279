import axios from 'axios'
import ConfigService from "../services/ConfigService";

class BubbleService {
    getBubble(bubbleId) {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/bubble/" + bubbleId
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    getAllActiveBubbles() {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/bubble/active"
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    getAllClosedBubbles() {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/bubble/closed"
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    archiveBubble(bubbleId) {
        return new Promise((resolve, reject) => {
            axios.delete(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/bubble/"+bubbleId
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }
}

const bubbleService = new BubbleService();
export default bubbleService;
