import React from 'react';
import {AppContext} from "../services/ContextService";
import GroupService from "../services/GroupService";
import ContactService from "../services/ContactService";
import Select from "react-select";
import ConfigService from "../services/ConfigService";
import "../styles/Group.css";

export default class Groups extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            groups: [],
            allGroupsSelect: [],
            groupsSelect: [],
            contactsSelect: [],
            contacts: [],
            contactToAdd: null,
            selectedOption: undefined,
            selectedGroupTypeOption: [],
            selectedLanguages: [],
            message: ""
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = async () => {
        let languages = ConfigService.languagesSelect(this.context.selectedChat);
        let reasons = ConfigService.reasonsForChatSelect(this.context.selectedChat);
        console.log(reasons);
        this.setState({message: "", selectedLanguages: languages, selectedGroupTypeOption: reasons});
        await this.getAllGroups();
        await this.getAllContacts();
        this.getContactNotInGroupForSelect(this.state.selectedOption);
    }

    getAllContacts = async () => {
        try {
            let response = await ContactService.getAllContacts();
            this.setState({contacts: response.contacts});
        } catch (e) {
            this.setState({message: "An error occurred while retrieving all contacts", e});
        }
    }

    getContactNotInGroupForSelect = (selectedOption) => {
        if ((selectedOption !== undefined) && this.state.groups[selectedOption]) {
            let that = this;
            let contactsSelect = this.state.contacts.reduce((result, contact) => {
                for (let groupContact of that.state.groups[selectedOption].users) {
                    if (contact.id === groupContact.id) {
                        return result;
                    }
                }
                return result.concat({ label: contact.loginEmail, value: contact.loginEmail });
            }, []);
            this.setState({contactsSelect: contactsSelect, contactToAdd: null});
        }
    }

    compareGroupName = (a, b) => {
        if (a.name < b.name) return 1;
        else return -1;
    }

    getAllGroups = async () => {
        try {
            let response = await GroupService.getAllGroups();
            const allGroupsSelect = response.groups.sort(this.compareGroupName).map((group, index) => (
                { label: group.name, value: index }
            ));
            let selectedGroupsList = this.getGroupsList(this.context.selectedReasons, this.state.selectedLanguages)
            let filteredGroupSelect = allGroupsSelect.filter((selectValue) => (selectedGroupsList.indexOf(selectValue.label) > -1))
            this.setState({groups: response.groups, groupsSelect: filteredGroupSelect, allGroupsSelect: allGroupsSelect, selectedGroupTypeOption: this.context.selectedReasons});

        } catch (e) {
            this.setState({message: "An error occurred while retrieving all contacts"+e, groups: [], groupsSelect: [], selectedOption: undefined});
        }
    }

    groupChange = (selectedOption) => {
        this.getContactNotInGroupForSelect(selectedOption.value);
        this.setState({ selectedOption: selectedOption.value });
    }

    groupTypeChange = (selectedOption) => {
        let groupsSelect = this.filterGroups(selectedOption, this.state.selectedLanguages);
        this.setState({ selectedGroupTypeOption: selectedOption, groupsSelect: groupsSelect });
        this.context.setUserReasons(selectedOption)
    }

    languageChange = (selectedOption) => {
        let groupsSelect = this.filterGroups(this.context.selectedReasons, selectedOption);
        this.setState({ selectedLanguages: selectedOption, groupsSelect: groupsSelect });
    }

    filterGroups(selectedReasons, selectedLanguages) {
        let groupsList = this.getGroupsList(selectedReasons, selectedLanguages);
        let allGroups = this.state.allGroupsSelect;
        return allGroups.filter((selectValue) => (groupsList.indexOf(selectValue.label) > -1));
    }

    getGroupsList(selectedReasons, selectedLanguages) {
        if (selectedLanguages.length === 0)
            selectedLanguages = ConfigService.languagesSelect(this.context.selectedChat);

        if (selectedReasons.length === 0)
            selectedReasons = ConfigService.reasonsForChatSelect(this.context.selectedChat);

        return selectedReasons.map((option) => {
            if (option.noLanguages)
                return [option.groupPrefix];
            else if (option.groupPrefix !== "")
                return selectedLanguages.map((language) => option.groupPrefix + "-" + language.value);
            else
                return selectedLanguages.map((language) => language.value);
        }).reduce((arr, e) => arr.concat(e), []);
    }

    contactToAddChange = (selectedOption) => {
        this.setState({ contactToAdd: selectedOption});
    }

    removeContactFromGroup = async (loginEmail, index) => {
        try {
            await GroupService.removeContactFromGroup(this.state.groups[this.state.selectedOption].name, loginEmail);
            let groups = this.state.groups;
            let users = groups[this.state.selectedOption].users;
            users.splice(index, 1);
            groups[this.state.selectedOption].users = users;

            let contactSelect = this.state.contactsSelect;
            contactSelect.push({ label:loginEmail, value:loginEmail });
            this.setState({groups: groups, contactsSelect: contactSelect});
        } catch (e) {
            this.setState({message: "An error occurred while removing "+loginEmail+" from this group"});
        }
    }

    addContactInGroup = async (groupName, loginEmail) => {
        try {
            await GroupService.addContactInGroup(groupName, loginEmail);
            this.setState({message: "User "+ loginEmail+ "has been added in the group, refresh the group to see the modification"});
        } catch (e) {
            this.setState({message: "An error occurred while adding "+ loginEmail + " in the group"});
        }
    }

    render() {
        let users = [];
        let groupSelected = this.state.groups.length > 0 && this.state.selectedOption !== undefined;
        if (groupSelected) {
            users = this.state.groups[this.state.selectedOption].users.map((user, index) => (
                <tr key={index}>
                    <td className={"no-border"}>{user.loginEmail}</td>
                    <td className={"td-right no-border"}><button className="redbutton" onClick={() => this.removeContactFromGroup(user.loginEmail, index)}>X</button></td>
                </tr>
            ))
        }

        let areFilterEmpty = this.state.selectedGroupTypeOption.length === 0 && this.state.selectedLanguages.length === 0;

        return <div>
            <div className={"left"}>
                <label>Filter on reason for chat: </label>
                <Select placeholder={"Reason for chat"} isMulti options={ConfigService.reasonsForChatSelect(this.context.selectedChat)} value={this.state.selectedGroupTypeOption} onChange={this.groupTypeChange}/>
            </div>
            <div className={"right"}>
                <label>Filter on languages: </label>
                <Select placeholder={"Languages"} isMulti options={ConfigService.languagesSelect(this.context.selectedChat)} value={this.state.selectedLanguages} onChange={this.languageChange}/>
            </div>
            <div className={"full-width"}>
                <label>Select a group : </label>
                <Select placeholder={"Group"} options={areFilterEmpty ? this.state.allGroupsSelect : this.state.groupsSelect} onChange={this.groupChange}/>

                <br />
                { groupSelected &&
                    <table className={"no-border"}>
                        <thead>
                        <tr>
                            <th className={"no-border"}>Users in the group</th>
                            <th className={"td-right no-border"}>
                                <button type="button" onClick={this.refresh}>Refresh</button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {users}
                        <tr>
                            <td className={"no-border"}><Select placeholder="Contact" isClearable={true} value={this.state.contactToAdd} options={this.state.contactsSelect} onChange={this.contactToAddChange}/></td>

                            <td className={"td-right no-border"}>{this.state.contactToAdd && <button className="greenbutton" onClick={() => this.addContactInGroup(this.state.groups[this.state.selectedOption].name, this.state.contactToAdd['value'])}>+</button>}</td>
                        </tr>
                        </tbody>
                    </table>
                }
                <p>{this.state.message}</p>
            </div>
        </div>
    }
}
