import axios from 'axios'
import ConfigService from "../services/ConfigService";

class LambdaService {

    formatParams(date, report_type, granularity, reasons) {
        let params = new URLSearchParams();
        params.append("event_date", date)
        params.append("report_type", report_type)
        params.append("granularity", granularity)

        const reasons_list = reasons.map((reason) => {
            return reason.value
        })
        for (let reason of reasons_list) {
            params.append("reason_for_chat", reason)
        }
        return params;
    }

    runReport(date, report_type, granularity, reasons, chat) {
        return new Promise((resolve, reject) => {
            let param = this.formatParams(date, report_type, granularity, reasons);
            axios.post(
                ConfigService.config.statServer.API_GATEWAY_URL + ConfigService.config.statServer.REPORT_BUILDER + "?chat=" + chat,
                null,
                {
                    params: param
                }
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response);
            }).catch(err => {
                console.log("Response status code", err.response ? err.response.status : err)
                reject(err.response ? err.response : {status: 500});
            })
        })
    }

    getEventsSummary(param) {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.statServer.API_GATEWAY_URL + ConfigService.config.statServer.EVENT_MANAGEMENT,
                {
                    params: param
                }
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response);
            }).catch(err => {
                console.log("Response status code", err.response ? err.response.status : err)
                reject(err.response ? err.response : {status: 500});
            })
        })
    }

    getbubbleEvents(bubbleId) {
        return new Promise((resolve, reject) => {
            let param = {bubble_id: bubbleId};
            axios.get(
                ConfigService.config.statServer.API_GATEWAY_URL + ConfigService.config.statServer.EVENT_MANAGEMENT,
                {
                    params: param
                }
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err.response ? err.response : {status: 500});
            })
        })
    }
}

const lambdaService = new LambdaService();
export default lambdaService;
