import React from 'react';
import { AppContext } from "../services/ContextService";
import "../styles/Display.css";
import BubbleService from "../services/BubbleService";
import ReasonSelect from "./ReasonSelector";

export default class BubblesClosed extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            bubbles: [],
            message: ''
        };
    }

    componentDidMount() {
        this.getAllArchivedBubbles();
    }

    compareCreationDate = (a, b) => {
        if (a.creationDate < b.creationDate) return 1;
        else return -1;
    }

    getAllArchivedBubbles = async () => {
        try {
            let response = await BubbleService.getAllClosedBubbles();
            const sortedBubblesList = response.bubbles.sort(this.compareCreationDate);
            this.setState({bubbles: sortedBubblesList});
        } catch (e) {
            this.setState({message: "Error occurred while getting all archived bubbles : " + e});
        }
    }

    render() {
        const reasonsList = this.context.selectedReasons.map((reason) => reason.value)
        let bubbles = this.state.bubbles.map((bubble, index) => (
            (!bubble.customData.lead || reasonsList.includes(bubble.customData.lead.reasonForChat)) && <tr key={index}>
                <td className={"no-border"}><a href={"/" + this.context.selectedChat + "/bubble/" + bubble.id}>{bubble.name}</a></td>
                <td className={"no-border"}>{bubble.customData.agentLanguage}</td>
                <td className={"no-border"}>{bubble.customData.agents &&
                                             bubble.customData.agents.length > 0 &&
                                             bubble.customData.agents[0] &&
                                             bubble.customData.agents[0].split('@')[0].replace('.', ' ')}</td>
                <td className={"no-border"}>{bubble.creationDate.slice(0, 19).replace('T', ' ')}</td>
                <td className={"no-border"}>
                    {
                        bubble.lastActivityDate ? bubble.lastActivityDate.slice(0, 19).replace('T', ' ')
                        : ""
                    }
                </td>
                <td className={"no-border"}>
                    {
                        bubble.customData.lead === undefined ? <span>Guest filling the form</span>
                        : <a href={"/" + this.context.selectedChat + "/lead/" + bubble.id}>Lead{bubble.customData.lead.sfdcId && " created"}</a>
                    }
                </td>
                <td className={"no-border"}> </td>
            </tr>
        ))
        return <div>
            <div className="full-width"><ReasonSelect /></div>
            <table className={"no-border"}>
                <thead>
                <tr>
                    <th className={"no-border"}>Bubble name</th>
                    <th className={"no-border"}>Language</th>
                    <th className={"no-border"}>Agent Name</th>
                    <th className={"no-border"}>Creation date</th>
                    <th className={"no-border"}>Archive date</th>
                    <th className={"no-border"}>Lead</th>
                    <th className={"td-right no-border"}>
                        <button type="button" onClick={this.getAllArchivedBubbles}>Refresh</button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {bubbles}
                </tbody>
            </table>
        </div>
    }
}
