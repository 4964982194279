import React from 'react';
import ConfigService from "../services/ConfigService";
import Select from "react-select";
import {AppContext} from "../services/ContextService";
import BubbleService from "../services/BubbleService";
import LeadService from "../services/LeadService";
import "../styles/Display.css";
import languages from "../config/languages.json"
import accountRelationship from "../config/accountRelationship.json"
import verticals from "../config/verticals.json"
import companyClassification from "../config/companyClassification.json"
import countryCodes from "../config/countryCodes.json"
import ConversationService from "../services/ConversationService";
import {getLeadDetails} from "../services/LeadFormatService";

export default class Leads extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            bubbleId: window.location.pathname.split("/").at(-1),
            bubble: '',
            lead: {},
            leadSfdcId: "",
            readOnlyLead: true,
            message: '',
            errorMessage: '',
            countrySfdcId: '',
            relationshipSfdcId: '',
            languageSfdcId: '',
            purposeSfdcId: ''
        };
    }

    componentDidMount() {
        this.getBubble(this.state.bubbleId);
    }

    getBubble = async (bubbleId) => {
        try {
            let response = await BubbleService.getBubble(bubbleId);
            let customDataLead = response.customData["lead"]
            let sfdcLead = await getLeadDetails.call(this, customDataLead, response.customData["agents"][0], this.context.selectedChat);
            this.setState({
                bubble: response, lead: sfdcLead, countrySfdcId: sfdcLead["Country__c"],
                relationshipSfdcId: sfdcLead["Relationship__c"], languageSfdcId: sfdcLead["Language_lead__c"],
                purposeSfdcId: sfdcLead["Chat_Purpose__c"], leadSfdcId: customDataLead["sfdcId"],
                readOnlyLead: (customDataLead["sfdcId"] !== "")
            });
        } catch (e) {
            this.setState({message: "", errorMessage: "Error occurred while getting all active bubbles : " + e});
        }
    }

    myChangeHandler = (event) => {
        let name = event.target.name;
        let val = event.target.value;

        let currentLead = this.state.lead
        currentLead[name] = val
        this.setState({lead: currentLead, message: "", errorMessage: ""});
    }

    getLabel = ((valuesList, valueToSearch) => {
        let filteredList = valuesList.filter((valuesList) => valuesList["sfdcId"] === valueToSearch)
        if (filteredList.length > 0) {
            return filteredList[0]["label"]
        }
    })

    myListChangeHandler = (event, name) => {
        let val = event.value;
        let currentLead = this.state.lead
        currentLead[name] = val
        this.setState({lead: currentLead, message: "", errorMessage: ""});
    }

    createLead = async () => {
        if (!this.state.lead.FirstName) {
            this.setState({errorMessage: "First Name is required"})
        } else if (!this.state.lead.LastName) {
            this.setState({errorMessage: "Last Name is required"})
        } else if (!this.state.lead.Company) {
            this.setState({errorMessage: "Company name is required"})
        } else if (!this.state.lead.Country__c) {
            this.setState({errorMessage: "Country name is required"})
        } else if (!this.state.lead.Chat_Purpose__c) {
            this.setState({errorMessage: "Chat purpose name is required"})
        } else if (!this.state.lead.CurrencyIsoCode) {
            this.setState({errorMessage: "Please select currency"})
        } else {
            this.setState({message: "Retrieving conversation transcript..."})
            let sfdcLead = this.state.lead;
            try {
                let conversationEvents = await ConversationService.getConversation(this.state.bubble.jid);
                sfdcLead["Description"] = conversationEvents.conversation.map(
                    (event) => event.from + ": " + event.content).join("\n");
            } catch (err) {
                console.log("Exception happened while retrieving conversation", err)
            }
            this.setState({message: "Creating lead in SalesForce..."})
            try {
                let response = await LeadService.createLead({"bubbleId": this.state.bubbleId,
                    "sfdcLead": sfdcLead});
                this.setState({
                    readOnlyLead: true,
                    message: "Lead successfully created",
                    errorMessage: "",
                    leadSfdcId: response.leadId
                })
            } catch (e) {
                this.setState({message: "", errorMessage: "Error occurred while creating lead in SFDC: " + e.response.data.message});
            }
        }
    }

    renderLookupSelect = (readOnly, name, valuesList, placeholder) => {
        if (readOnly) {
            return <input name={name} type="text" readOnly={true}
                          defaultValue={this.getLabel(valuesList, this.state.lead[name])}/>
        } else {
            let extraProps = {}
            if (this.state.lead[name]) {
                extraProps.defaultValue = {
                    value: this.state.lead[name],
                    label: valuesList.filter((valuesList) => (valuesList["sfdcId"]) === this.state.lead[name])[0].label
                }
            }
            if (placeholder) {
                extraProps.placeholder = placeholder
            } else {
                extraProps.placeholder = ""
            }
            return <div className={"left"}>
                <Select name={name}
                        options={valuesList.map((item) => {
                            return {value: item.sfdcId, label: item.label}
                        })}
                        {...extraProps}
                        onChange={(event) => this.myListChangeHandler(event, name)}/>
            </div>
        }
    }

    renderSimpleSelect = (readOnly, name, valuesList, placeholder) => {
        return this.renderLookupSelect(readOnly, name,
            valuesList.map((item) => { return {value: item, label: item, sfdcId: item}}), placeholder)
    }

    render() {
        let bubble = this.state.bubble;
        let lead = this.state.lead;
        let readOnlyLead = this.state.readOnlyLead;
        let bubbleRow = "";
        if (bubble) {
            bubbleRow =
                <tr>
                    <td className={"no-border"}>{bubble.name}</td>
                    <td className={"no-border"}>{bubble.customData.agentLanguage}</td>
                    <td className={"no-border"}>{bubble.creationDate.slice(0, 19).replace('T', ' ')}</td>
                    <td className={"no-border"}>{bubble.lastActivityDate.slice(0, 19).replace('T', ' ')}</td>
                </tr>
        }

        let countryField = this.renderLookupSelect(readOnlyLead, "Country__c", countryCodes, "Select country (mandatory)");
        let relationshipField = this.renderLookupSelect(readOnlyLead, "Relationship__c", accountRelationship);
        let purposeField = this.renderLookupSelect(readOnlyLead, "Chat_Purpose__c", ConfigService.reasonForChat(this.context.selectedChat));
        let languageField = this.renderLookupSelect(readOnlyLead, "Language_lead__c", languages);
        let industryField = this.renderSimpleSelect(readOnlyLead, "Industry", verticals);
        let marketCategoryField = this.renderSimpleSelect(readOnlyLead, "Market_Category__c", companyClassification);
        let currencyField = this.renderSimpleSelect(readOnlyLead, "CurrencyIsoCode", ["EUR", "USD"], "Select Currency (mandatory)");

        return <div>
            {bubble &&
            <table className={"no-border"}>
                <thead>
                <tr>
                    <th className={"no-border"}>Bubble name</th>
                    <th className={"no-border"}>Language</th>
                    <th className={"no-border"}>Creation date</th>
                    <th className={"no-border"}>Last activity date</th>
                </tr>
                </thead>
                <tbody>
                {bubbleRow}
                </tbody>
            </table>
            }
            <h2>Lead information{readOnlyLead && " (Already created in SalesForce)"}</h2>
            {readOnlyLead && <a href={ConfigService.config.sfdcInstance.SFDC_URL + "/" + this.state.leadSfdcId} target="_blank" rel="noreferrer">
                Display lead in SalesForce</a>}
            <form name="leadInputForm">
                {bubble && <div>
                    <div className="menu">
                        <li className="nochip"><input name="FirstName" type="text"
                                                      defaultValue={lead.FirstName} readOnly={readOnlyLead}
                                                      onChange={this.myChangeHandler}/> <strong>First Name*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><input name="LastName" type="text"
                                                      defaultValue={lead.LastName} readOnly={readOnlyLead}
                                                      onChange={this.myChangeHandler}/> <strong>Last Name*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><input name="Email" type="text"
                                                      defaultValue={lead.Email} readOnly={readOnlyLead}
                                                      onChange={this.myChangeHandler}/> <strong>e-mail</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><input name="Phone" type="text" readOnly={readOnlyLead}
                                                      defaultValue={lead.Phone}
                                                      onChange={this.myChangeHandler}/> <strong>Phone number</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><input name="Company" type="text" readOnly={readOnlyLead}
                                                      defaultValue={lead.Company}
                                                      placeholder={"Enter company name (mandatory)"}
                                                      onChange={this.myChangeHandler}/> <strong>Company name*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {countryField}<strong>Country*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {relationshipField}<strong> End User Type</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {purposeField}<strong> Chat purpose*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {industryField}<strong> Vertical</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {marketCategoryField}<strong> Company Classification</strong>
                        </li>
                    </div>

                    <div className="menu">
                        <li className="nochip">
                            {languageField}<strong> Language</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip">
                            {currencyField} <strong>Currency code*</strong>
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><textarea name="Appointment_Comments__c" rows="5" cols="80"
                            defaultValue={lead.Appointment_Comments__c} readOnly={readOnlyLead}
                                                      onChange={this.myChangeHandler}/> <strong> Comments</strong>
                        </li>
                    </div>
                    {readOnlyLead && <div className="menu">
                        <li className="nochip"><textarea name="Description" rows="5" cols="80"
                                                         defaultValue={lead.Description} readOnly={this}/>
                            <strong> Transcript</strong>
                        </li>
                    </div>}
                    {!readOnlyLead &&
                        <button type="button" onClick={this.createLead}>Create lead in SFDC</button>
                    }
                    <p className="redback">{this.state.errorMessage}</p>
                    <p>{this.state.message}</p>
                </div>}
            </form>
        </div>

    }
}
