import React from "react";
import "../styles/Home.css";

export default function Home() {
    return (
        <div className="Home">
            <div className="lander">
                <h1>ULC-Management</h1>
                <p>An app to manage Universal LIve Chat reports and dashboards</p>
            </div>
        </div>
    );
}
