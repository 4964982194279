import axios from 'axios'
import ConfigService from "./ConfigService";
import { AppContext } from "./ContextService";

class ContactService {
    static contextType = AppContext;

    getAllContacts() {
        return new Promise((resolve, reject) => {
            axios.get(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/contact"
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    sendInvitations(email) {
        return new Promise((resolve, reject) => {
            axios.post(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/contact",
                {
                    emails: email
                }
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }

    removeContact(email) {
        return new Promise((resolve, reject) => {
            axios.delete(
                ConfigService.config.chatServer.URL + ConfigService.config.chatServer.ADMINISTRATION + "/contact/"+email
            ).then( response => {
                if (ConfigService.config.debug) console.log(response);
                resolve(response.data);
            }).catch(err => {
                console.log("Response status code", err.data)
                reject(err);
            })
        })
    }
}

const contactService = new ContactService();
export default contactService;
