import React from 'react';
import LambdaService from '../services/LambdaService';
import BubbleService from "../services/BubbleService";
import {AppContext} from "../services/ContextService";
import GroupService from "../services/GroupService";
import "../styles/Display.css";
import ConfigService from "../services/ConfigService";
import {getLeadDetails} from "../services/LeadFormatService";

export default class BubbleDetails extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            bubbleId: window.location.pathname.split("/").at(-1),
            message: '',
            errorMessage: '',
            summaryMessage: '',
            bubble: '',
            lead: '',
            bubblesSummary: [],
            groups: [],
            allAgents: [],
        };
    }

    componentDidMount() {
        this.reloadForm()
    }

    reloadForm = () => {
        this.getBubble(this.state.bubbleId)
        this.getSummary(this.state.bubbleId);
    }

    getSummary = (bubbleId) => {
        this.setState({errorMessage: "", summaryMessage: "Query bubble data ...", bubblesSummary: [], bubbleDetails: []});
        const param = {bubble_id: bubbleId, summary: 'true', chat: this.context.selectedChat};
        LambdaService.getEventsSummary(param).then(response => {
            if (response.status === 200) {
                this.getAllGroups(response.data[0].reason_for_chat)
                this.setState({summaryMessage: "", bubblesSummary: response.data});
            } else
                this.setState({summaryMessage: "No data returned", bubblesSummary: []});
        }).catch(err => {
            this.setState({summaryMessage: "Error while querying bubble summary"});
            console.log(err);
        })
    }

    getBubble = async (bubbleId) => {
        try {
            let response = await BubbleService.getBubble(bubbleId);
            let customDataLead = response.customData["lead"]
            if (customDataLead) {
                let sfdcLead = await getLeadDetails.call(this, customDataLead, response.customData["agents"][0], this.context.selectedChat);
                this.setState({
                    bubble: response, lead: sfdcLead
                });
            }
        } catch (e) {
            this.setState({message: "", errorMessage: "Error occurred while getting bubble or lead details : " + e});
        }
    }

    getAllGroups = async (reasonForChat) => {
        if (reasonForChat) {
            try {
                let response = await GroupService.getAllGroups();
                const groupPrefix = this.reasonForChatPrefix(reasonForChat)
                console.log("Group prefix", groupPrefix)
                let usersList = {};
                for (let userGroup of response.groups) {
                    if (userGroup.name.includes(groupPrefix)) {
                        for (let user of userGroup.users) {
                            usersList[user.id] = user.firstName + " " + user.lastName}
                    }
                }
                console.log("All agents for this reason", usersList)
                this.setState({allAgents: usersList});
            } catch (e) {
                this.setState({message: "An error occurred while retrieving groups" + e, groups: []});
            }
        }
    }

    reasonForChatlabel = (reasonId) => {
        try {
            return ConfigService.reasonForChat(this.context.selectedChat).filter((valuesList) => valuesList["value"] === reasonId)[0].label
        } catch (err) {
            console.log("Couldn't find label for " + reasonId)
            return ""
        }
    }

    reasonForChatPrefix = (reasonId) => {
        try {
            return ConfigService.reasonForChat(this.context.selectedChat).filter((valuesList) => valuesList["value"] === reasonId)[0].groupPrefix
        } catch (err) {
            console.log("Couldn't find prefix for " + reasonId)
            return ""
        }
    }

    buildBubblesList = (bubblesSummary) => {
        try {
            const reasonsList = this.context.selectedReasons.map((reason) => reason.value)
            return bubblesSummary.map((bubble, index) => {
                    if (bubble.language && reasonsList.includes(bubble.reason_for_chat)) {
                        return <tr key={index}>
                            <td className={"no-border"}>{bubble.status}</td>
                            <td className={"no-border"}>{this.reasonForChatlabel(bubble.reason_for_chat)}</td>
                            <td className={"no-border centered-cell"}>{bubble.language}</td>
                            <td className={"no-border"}>{bubble.date}</td>
                            <td className={"no-border"}>{bubble.start_time && bubble.start_time.substr(11, 5)}</td>
                            <td className={"no-border"}>{bubble.end_time && bubble.end_time.substr(11, 5)}</td>
                            <td className={"no-border centered-cell"}>{bubble.guest_message_number}</td>
                            <td className={"no-border centered-cell"}>{bubble.agent_message_number}</td>
                            <td className={"no-border centered-cell"}>{bubble.guest_message_number && bubble.first_agent_response_timestamp &&
                            Math.round(bubble.first_agent_response_timestamp - bubble.first_guest_message_timestamp)}</td>
                        </tr>
                    } else {
                        return []
                    }
                }
            )
        } catch (err) {
            console.log("Exception occurred while creating bubbles list: ", err)
            return []
        }
    }

    buildEventsList = (bubbleSummary) => {
        if (bubbleSummary.length > 0) {
            console.log(bubbleSummary[0].events)
            return bubbleSummary[0].events.map((event, index) => {
                    return <tr key={index}>
                        <td className={"no-border"}>{event.action}</td>
                        <td className={"no-border"}>{event.timestamp.substr(11, 8)}</td>
                        <td className={"no-border"}>{event.sfdc_lead_id ?
                            <a href={ConfigService.config.sfdcInstance.SFDC_URL + "/" + event.sfdc_lead_id} target="_blank"
                               rel="noreferrer">SalesForce Lead</a> :
                            event.name}</td>
                        <td className={"no-border"}>{event.text} {event.user_language} {event.user_email}</td>
                    </tr>
                }
            )
        } else return []
    }

    buildAgentsList = (allAgents, bubble) => {
        try {
            let usersArray = []
            let membersArray = []
            if (bubble.users) {
                usersArray = bubble.users.map((member) => {
                    return member.userId
                })
            }
            if (bubble.members) {
                membersArray = bubble.members.map((member) => {
                    return member.userId
                })
            }
            return Object.entries(allAgents).map(([agentId, agentName], key) => {
                    return <tr key={key}>
                        <td className={"no-border"}>{agentName}</td>
                        <td className={"no-border"}>{(usersArray.includes(agentId) ?
                            <button className={"redbutton"} name={agentName} value={agentId}
                                    onClick={this.agentRemover}>Remove</button> : "")}</td>
                        <td className={"no-border"}>{((usersArray.includes(agentId) || membersArray.length === 0) ? "" :
                            <button className={"greenbutton"} name={agentName} value={agentId}
                                    onClick={this.agentAdder}>Add into conversation</button>)}</td>
                    </tr>})
        } catch (err) {
            console.log("Exception occurred while creating events list: ", err)
            return []
        }
    }

    agentRemover = (event) => {
        let agentId = event.target.name;
        let val = event.target.value;
        console.log("Remove agent ", agentId, val)
    }

    agentAdder = (event) => {
        let agentId = event.target.name;
        let val = event.target.value;
        console.log("Add agent ", agentId, val)
    }

    render() {
        let lead = this.state.lead;
        let bubbles = this.buildBubblesList(this.state.bubblesSummary)
        let events = this.buildEventsList(this.state.bubblesSummary)
        let agents = this.buildAgentsList(this.state.allAgents, this.state.bubble)

        return <div className="full-width">
            <div className="full-width">
                <p>{this.state.errorMessage}</p>
                <p>{this.state.message}</p>
                <div className={"full-width"}>
                    <p>{this.state.summaryMessage}</p>
                </div>
                {(bubbles.length > 0) && <div>
                    <h1>Bubble {this.state.bubblesSummary[0].bubble_name}<button name={"reload"} onClick={this.reloadForm}>Reload</button></h1>
                    <table className={"no-border"}>
                    <thead>
                    <tr>
                        <th className={"no-border"}>Status</th>
                        <th className={"no-border"}>Reason for chat</th>
                        <th className={"no-border"}>Language</th>
                        <th className={"no-border"}>Date</th>
                        <th className={"no-border"}>Start</th>
                        <th className={"no-border"}>End</th>
                        <th className={"no-border centered-cell"}>Guest messages</th>
                        <th className={"no-border centered-cell"}>Agent messages</th>
                        <th className={"no-border centered-cell"}>Response time (s)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bubbles}
                    </tbody>
                    </table>
                </div>}
                {(lead) && <div>
                    <h1>Guest information</h1>
                    <div className="menu">
                        <li className="nochip"><strong>First Name: </strong>{lead.FirstName}
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><strong>e-mail: </strong>{lead.Email}
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><strong>Last Name: </strong>{lead.LastName}
                        </li>
                    </div>
                    <div className="menu">
                        <li className="nochip"><strong>Company name: </strong>{lead.Company}
                        </li>
                    </div>
                </div>}
                {(agents.length < 0) && <div>
                    <h1>Agents engaged</h1>
                    <table className={"no-border"}>
                        <thead>
                        <tr>
                            <th className={"no-border"}>Agent name</th>
                            <th className={"no-border"}>Engaged</th>
                            <th className={"no-border"}>Not Engaged</th>
                        </tr>
                        </thead>
                        <tbody>
                        {agents}
                        </tbody>
                    </table>
                </div>}
                {(events.length > 0) && <div>
                <h1>Bubble events</h1>
                <table className={"no-border"}>
                    <thead>
                    <tr>
                        <th className={"no-border"}>Action</th>
                        <th className={"no-border"}>Time</th>
                        <th className={"no-border"}>Name</th>
                        <th className={"no-border"}>Text</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events}
                    </tbody>
                </table>
                </div>}
            </div>
        </div>
    }
}
