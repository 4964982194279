import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Amplify } from 'aws-amplify';
import ConfigService from "./services/ConfigService";
import App from './components/App'
// import 'bootstrap/dist/css/bootstrap.min.css';

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: ConfigService.config.cognito.REGION,
        userPoolId: ConfigService.config.cognito.USER_POOL_ID,
        userPoolWebClientId: ConfigService.config.cognito.APP_CLIENT_ID,
        oauth: {
            domain: ConfigService.config.cognito.DOMAIN,
            responseType: 'code',
            redirectSignIn: ConfigService.config.cognito.REDIRECT_SIGN_IN,
            redirectSignOut: ConfigService.config.cognito.REDIRECT_SIGN_OUT
        },
    }
});


ReactDOM.render(
    <CookiesProvider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </CookiesProvider>,
    document.getElementById('root')
);
